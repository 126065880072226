import React, { useContext, useEffect, useState } from "react";

const useScrollStop = (initialState = false) => {
  const [isScrolling, setIsScrolling] = useState(initialState);

  useEffect(() => {
    let timer;
    const delay = 1000;
    const handleScroll = () => {
      clearTimeout(timer);
      setIsScrolling(true);
      timer = setTimeout(function () {
        setIsScrolling(false);
      }, delay);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrolling]);

  return [isScrolling];
};

export default useScrollStop;
