import React, { useContext, useEffect } from "react";
import Image from "next/image";
import absoluteUrl from "next-absolute-url";
import classNames from "classnames";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "next-i18next";
import {
  OGMetaDataHead,
  HeroSubText,
  Items,
  Layout,
  Loader,
  CategoryNav,
  WhatsAppQuickChat,
  ProductSearch,
} from "components";
import AuthContext from "context/Auth";
import AuthService from "services/auth";
import StoreService from "services/store";
import storageService from "utils/storage";
import GeoLocationService from "services/country";
import useScrollStop from "hooks/useScrollStop";
import { asyncAwaitWrap } from "utils/helpers";
import {
  generateSubdomainFromLocale,
  getCountryFromLocale,
  allowedCountries,
} from "utils/app";
import { setDefaultAuthHeader, setUserLang } from "utils/axios";
import { APP_SESSION_EXPIRY_IN_SECONDS } from "config/constants";

const setGeoToVault = (geo) => {
  storageService.setToStorage("Session", "__zbnGeo", geo, {
    expires: `+${APP_SESSION_EXPIRY_IN_SECONDS} seconds`,
  });
};

const Home = ({ store, origin, geoLocation }) => {
  const { t: trans } = useTranslation("common");
  const { auth } = useContext(AuthContext);
  const [isScrolling] = useScrollStop(false);
  const { ref: searchInputFloating, inView } = useInView();
  const storeNameCaseLower = store?.subdomain?.toLowerCase();

  useEffect(() => {
    const geoLocationInSession = storageService.getFromStorage(
      "Session",
      "__zbnGeo"
    );
    if (geoLocationInSession) {
      return;
    }
    setGeoToVault(geoLocation);
  }, [geoLocation]);

  return (
    <React.Fragment>
      <OGMetaDataHead store={store} origin={origin} page={"store"} />
      {!auth ? (
        <Loader />
      ) : (
        <Layout containerClass="page-container__home">
          <WhatsAppQuickChat />
          <div
            className={classNames(
              "search-container search-container__static fixed top-70 z-1",
              {
                hidden: isScrolling || inView,
              }
            )}
          >
            <ProductSearch />
          </div>
          <div className="relative">
            <div className="relative max-w-5xl mx-auto">
              <div className="flex flex-col min-h-screen lg:flex-row lg:items-center">
                <div className="flex flex-col flex-grow bg-white lg:overflow-hidden">
                  <div className="p-6 bg-gray-900 text-white hidden">
                    <p className="text-center text-sm md:text-base">
                      {trans(
                        "Enjoy free delivery across the UAE, Free Gift wrapping available"
                      )}
                    </p>
                  </div>
                  <div className="relative pb-2/3 text-center">
                    <Image
                      src={`https://media.zbooni.shop/assets/web/enterprise/${storeNameCaseLower}/hero-banner.png`}
                      alt={`banner-${storeNameCaseLower}`}
                      width={960}
                      height={640}
                      layout="intrinsic"
                      className="mx-auto"
                    />
                  </div>
                  <div className="bg-white">
                    <div className="is-visible" ref={searchInputFloating}>
                      <div className="pt-2 search-container search-container__floating">
                        <ProductSearch />
                      </div>
                    </div>
                    <HeroSubText store={store} />
                    <CategoryNav />
                    <Items store={store} products={store.products} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </React.Fragment>
  );
};

export const getServerSideProps = async ({ req, locale }) => {
  const { data } = await AuthService.getToken();
  setDefaultAuthHeader(data.access_token);
  const { origin } = absoluteUrl(req);
  const countryInLocale = getCountryFromLocale(locale);
  const is_root = req.url == "/";
  const [geoLocationResponseError, geoLocationResponse] = await asyncAwaitWrap(
    GeoLocationService.fetchCountryByIP(req.headers)
  );
  const geoLocation = geoLocationResponse?.data;
  const countryFromGeoLocation = geoLocation?.geo?.country;
  const countryFromGeoLocationCaseLower = countryFromGeoLocation?.toLowerCase();
  const isGeoLocationSupported = allowedCountries.includes(
    countryFromGeoLocationCaseLower
  );
  setUserLang(locale);

  if (
    isGeoLocationSupported &&
    is_root &&
    countryInLocale !== countryFromGeoLocationCaseLower
  ) {
    return {
      redirect: {
        destination: `/en-${countryFromGeoLocation}/`,
        permanent: true,
      },
    };
  }
  const subdomain = generateSubdomainFromLocale(locale);
  const [storeApiError, storeApiResponse] = await asyncAwaitWrap(
    StoreService.getStoreBySubdomain(subdomain)
  );
  if (storeApiError || !storeApiResponse?.data?.count) {
    return {
      notFound: true,
    };
  }
  const { data: storeObject } = storeApiResponse;
  return {
    props: {
      store: storeObject?.results?.[0],
      origin,
      geoLocation,
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
};

export default Home;
